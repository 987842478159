import React from "react";
import DevelopmentPageTemplate from "../../templates/development-page";
import { Alert } from "react-bootstrap";
import "../../threeplay.scss";

export default class Index extends React.Component {
  render() {
    var overview = (
      <>
        <p>
          Alerts should be used sparingly to indicate contextual messages related to actions a user can perform. Alerts should primarily be used to display important information to a user, but can also be used to highlight an action a user has to perform in order to proceed.
        </p>
        <p>
          3Play uses three main types of alerts - success, danger, and warning alerts. Alerts can contain a header, subtext, a link, and an optional dismiss button. Danger and warning alerts can also contain a checkbox to require a user to actively acknowledge or confirm important information before proceeding.
        </p>
      </>
    );

    var codeString = `
    <Alert key="1" variant="success">
      This is a success alert.
    </Alert>
    <Alert key="2" variant="warning">
      This is a warning alert.
    </Alert>
    <Alert key="3" variant="danger">
      This is a danger alert.
    </Alert>
    <br />
    <Alert variant="warning" dismissible>
      <Alert.Heading>Alert Heading</Alert.Heading>
      <p>
        Aww yeah, you successfully read this important alert message. This example
        text is going to run a bit longer so that you can see how spacing within an
        alert works with this kind of content.
      </p>
      <hr />
      <p className="mb-0">
        Whenever you need to, be sure to use margin utilities to keep things nice
        and tidy.
      </p>
    </Alert>
    `;

    var code = (
      <>
        <br />
        <Alert key="1" variant="success">
          This is a success alert.
        </Alert>
        <Alert key="2" variant="warning">
          This is a warning alert.
        </Alert>
        <Alert key="3" variant="danger">
          This is a danger alert.
        </Alert>
        <br />
        <Alert variant="warning" dismissible>
          <Alert.Heading>Alert Heading</Alert.Heading>
          <p>
            Aww yeah, you successfully read this important alert message. This example
            text is going to run a bit longer so that you can see how spacing within an
            alert works with this kind of content.
          </p>
          <hr />
          <p className="mb-0">
            Whenever you need to, be sure to use margin utilities to keep things nice
            and tidy.
          </p>
        </Alert>
        <br />
      </>
    );

    var codes = [
      {
        code: code,
        codeLanguage: "html",
        codeString: codeString
      }
    ]

    var accessibilitySection = (
      <>
        <h5>Alerts should not rely solely on color.</h5>
        <p>When choosing which alert variant to show, be aware that the color should not be the only signal to indicate to the user what type of alert they are looking at. Use icons or aria tags to indicate what type of alert is being shown, and make the text descriptive enough to indicate what type of message is being conveyed.</p>

        <h5>Alerts should be dismissable.</h5>
        <p>
          If an alert is only shown to provide conditional messaging and doesn't require the user to actively confirm anything, allow the user to dismiss the alert. Alerts should not dismiss themselves as control should be given to the user to specify dismissal themselves, in accordance with WCAG 2.1.
        </p>
      </>
    );

    return (
      <DevelopmentPageTemplate
        title="Alerts"
        overview={overview}
        codes={codes}
        accessibilitySection={accessibilitySection}
      />
    );
  }
}
